import React, { useEffect, useState } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
//import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import ImplantationSingleCard from '../../../components/ImplantationSingleCard/ImplantationSingleCard';
import PageBannerMap from '../../../components/PageBannerMap/PageBannerMap';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

import './styles.scss';

let classNames = require('classnames');

const googleMapApiKey = 'AIzaSyALtHppPCXS8vFUqjTwh8U917356CwFgCE'; // AIzaSyDs8nXMWi7D7ROZ7hieXcR7cypOOQIZf4E

const ImplantationPage = ({ data }) => {
  const imagesArray = data?.allImages?.edges;
  const allSiegeData = data?.allSiegeData.edges;
  const implantationData = data?.implantationData.edges[0].node;
  const listImg = data.file?.childImageSharp;

  let siegeListArr = [];
  let allSiteArr = [];

  const axios = require('axios').default;

  const [gmapInitialised, setGmapInitialised] = useState(false);
  const [siegeList, setSiegeList] = useState([]);
  const [allCategoryList] = useState(data?.allSiteData.edges);
  const [searchResult, setSearchResult] = useState([]);
  const [allSites, setAllSites] = useState([]);

  const addSiege = (siege) => {
    // siegeListArr
    let continentAvail = siegeListArr.filter(a => a.continent === siege.node?.field_continent);
    let nodeObj = siege.node;
    Object.assign(nodeObj, { visuel: data.file?.childImageSharp });

    if (continentAvail.length > 0) {
      siegeListArr.filter(a => a.continent === nodeObj?.field_continent)[0]?.others.push(nodeObj);
    }
    else {
      siegeListArr.push(
        {
          continent: nodeObj?.field_continent,
          others: [nodeObj]
        }
      );
    }
    setSiegeList(siegeListArr);
  };

  const createAllSitesWithGraphData = (allList) => {
    allList.forEach((site => {
      if (site.node.field_latitude && site.node.field_longitude) {
        allSiteArr.push({
          city: site.node.field_city || 'Undefined on server',
          position: new google.maps.LatLng(site.node.field_latitude, site.node.field_longitude),
          title: site.node.title || 'Undefined on server',
          adresse: site.node.field_address,
          email: site.node.field_email,
          phone: site.node.field_telephone,
          website: site.node.field_website,
          code_post: site.node.field_code_postale
        });
      }
    }));
    setAllSites(allSiteArr);
  };

  useEffect(() => {
    window.renderMap = () => {
      setGmapInitialised(true);
      delete window.renderMap;
    };
    if (!gmapInitialised) {
      let s = document.createElement('script');
      s.src = '//maps.googleapis.com/maps/api/js?key=' + googleMapApiKey + '&libraries=places,geometry&callback=renderMap';
      s.type = 'text/javascript';
      s.id = 'gmaps-api';
      s.async = true;
      document.getElementsByTagName('head')[0].appendChild(s);
    }

    allSiegeData.forEach((siege => {
      addSiege(siege);
    }));
  }, []);

  useEffect(() => {
    if (gmapInitialised) {
      createAllSitesWithGraphData(allCategoryList);
    }
  }, [gmapInitialised]);

  const slider_setting_cards = {
    className: 'implantation_cards',
    centerMode: false,
    infinite: false,
    centerPadding: '5px',
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const onChangeAutoCompleteCity = (latLng) => {
    let nearestsites = [];
    allSites.map((site, index) => {
      let distance_from_geoloc = google.maps.geometry.spherical.computeDistanceBetween(latLng, site.position);
      distance_from_geoloc = distance_from_geoloc / 1000;
      site.distance_from_geoloc = distance_from_geoloc;
      if (distance_from_geoloc < 10) {
        console.log(site)
        nearestsites.push(site);
      }
    });
    setSearchResult(nearestsites);
    console.log(JSON.stringify({ message: nearestsites }));

    document.querySelector('#inlineFrameExample').contentWindow.postMessage(
      JSON.stringify({ message: nearestsites })
    );
    document.querySelector('#mapiframe').contentWindow.postMessage(
      JSON.stringify({ message: nearestsites })
    );
  };

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={implantationData.title}
        description="Description de la page About us"
      />
      <div className={classNames('page_template', 'implantations_page')}>
        {
          allSites.length > 0 && (
            <PageBannerMap
              iframeURL="https://colas-implantations-monde.preprod.latitude-cartagene.com/"
              onChangeAutoCompleteCity={onChangeAutoCompleteCity}
              gmapInitialised={gmapInitialised}
              breadcrumbData={{
                //grandParentPage: { title: 'Acceuil', url: '/' },
                parentPage: { title: 'Groupe', url: '/' + implantationData.path?.landcode },
                currentPage: { title: implantationData.title, url: '/' + implantationData.path?.langcode + implantationData.path?.alias }
              }}
            >
              <div className="wrapper_page">
                <TitlePage
                  color="color_white"
                  title={implantationData.title}
                />
                {implantationData.relationships?.field_blocs?.map((block, i) => {
                  if (block.__typename === 'block_content__block_chiffres_clefs') {
                    return (<div key={i}>
                      <div className="col_2">
                        <div className="col graph">
                          <GraphRH
                            text={block.relationships?.field_values[0]?.field_legend?.processed}
                            jobs={block.relationships?.field_values[0]?.field_chiffre}
                            textColor="color_white"
                          />
                        </div>
                        <div className="col text" dangerouslySetInnerHTML={{ __html: block?.body?.processed }}>

                        </div>
                      </div>
                    </div>);
                  }
                })}

              </div>
            </PageBannerMap>
          )
        }
        {/* <iframe id="inlineFrameExample" //TEST IFRAME using postmessage
          title="Inline Frame Example"
          width="100%"
          height="500px"
          src="/iframe">
        </iframe> */}
        {
          searchResult.length > 0 && (
            <section className={classNames('section_content', 'search_result_offices')}>
              <div className="wrapper_page">
                <div className="search_results">
                  <h5>{searchResult.length} résultats correspondent à votre recherche</h5>
                  <div className="lists_results">
                    {
                      searchResult?.map((card, index) => (
                        <ImplantationSingleCard
                          key={index}
                          visuel={listImg}
                          title={card.title}
                          adresse={card.adresse}
                          details={'<p>' + card.phone[0] && card.phone[0] + '</p>' + '<p>' + card.phone[1] && card.phone[1] + '</p>' + '<p>' + card.email && card.email + '</p>' + '<p>' + card.website && card.website + '</p>'}
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
            </section>
          )
        }
        <section className={classNames('section_content', 'section_region_list', { 'hide': searchResult.length > 0 })}>

          {siegeList.map((siege, i) => (
            <>
              <div className="wrapper_page" key={i}>
                <TitleSection
                  title={siege.continent}
                  description={siege.others?.length + ' sites implantés'}
                  type="line"
                />
                <div className="listCardsDesktop">
                  {
                    siege.others?.map((card, index) => (
                      <ImplantationSingleCard
                        key={index}
                        visuel={card?.visuel}
                        title={card?.title}
                        adresse={card?.field_address && card?.field_address}
                        tel1={Array.isArray(card?.field_telephone) ? card?.field_telephone[0] : card?.field_telephone}
                        tel2={Array.isArray(card?.field_telephone) ? card?.field_telephone[1] : null}
                        email={card.field_email}
                        website={card.field_website}
                      />

                    ))
                  }
                </div>
              </div>
              <div className="listCardsMobile">
                <SliderComponent settings={slider_setting_cards}>
                  {
                    siege.others?.map((card, index) => (
                      <ImplantationSingleCard
                        key={index}
                        visuel={card.visuel}
                        title={card.title}
                        adresse={card?.field_address && card?.field_address}
                        tel1={Array.isArray(card?.field_telephone) ? card?.field_telephone[0] : card?.field_telephone}
                        tel2={Array.isArray(card?.field_telephone) ? card?.field_telephone[1] : null}
                        email={card.field_email}
                        website={card.field_website}
                      />
                    ))
                  }
                </SliderComponent>
              </div>
            </>
          ))}

        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
query implatationTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  implantationData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        path {
          alias
          langcode
        }
        field_type
        langcode
        body {
          processed
        }
        relationships {
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__block_chiffres_clefs {
                id
                body {
                  processed
                }
                field_title {
                  processed
                }
                relationships {
                  field_values {
                    field_chiffre
                    field_legend {
                      processed
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  allSiteData: allNodeNosImplantations(
    filter: {langcode: {eq: $locale}}
  ) {
    edges {
      node {
        field_address
        field_city
        field_code_postale
        field_email
        field_latitude
        field_longitude
        field_telephone
        field_type_etablissement
        field_website
        relationships {
          field_image {
            drupal_internal__mid
          }
        }
        title
        path {
          alias
          langcode
        }
        langcode
        drupal_id
        field_continent
      }
    }
  }

  allSiegeData: allNodeNosImplantations(
    filter: {langcode: {eq: $locale}, field_type_etablissement: {eq: "Siège"}}
  ) {
    edges {
      node {
        field_address
        field_city
        field_code_postale
        field_email
        field_latitude
        field_longitude
        field_telephone
        field_type_etablissement
        field_website
        relationships {
          field_image {
            drupal_internal__mid
          }
        }
        title
        path {
          alias
          langcode
        }
        langcode
        drupal_id
        field_continent
      }
    }
  }
  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 332, height: 332) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        langcode
      }
    }
  }

  file(relativePath: {eq: "slider-image.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;

export default ImplantationPage;
