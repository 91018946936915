import React, { useState, useEffect } from 'react';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import BGArrowPointerBottomMobile from '../../assets/images/bg-page-banner-v2-bottom-mobile.inline.svg';
import BGArrowPointerBottomLeft from '../../assets/images/bg-page-banner-v2-bottom.inline.svg';
import BGArrowPointerTopMobile from '../../assets/images/bg-page-banner-v2-top-mobile.inline.svg';
import BGArrowPointerTopLeft from '../../assets/images/bg-page-banner-v2-top.inline.svg';
import { ShapeYellowArrowBanner, SearchIcon } from '../../Atoms/Icons/Icons';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ScrollIcon from '../../Molecules/ScrollIcon/ScrollIcon';

import './PageBannerMap.scss';
let classNames = require('classnames');

const PageBannerMap = (props) => {
  const intl = useIntl();
  const { iframeURL, breadcrumbData, gmapInitialised, children } = props;
  const [fullVisuel, setFullVisuel] = useState(false);
  const [showMap, setShowMap] = useState(false);
  //const [mapInit, setMapInit] = useState(gmapInitialised);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      window.addEventListener('scroll', onPageScroll);
      //onPageScroll();
    }
    return () => window.removeEventListener('scroll', onPageScroll);
  }, []);

  useEffect(() => {
    if (gmapInitialised) {
      const input = document.getElementById('pac-input');
      const options = {
        componentRestrictions: {}, //country: "fr"
        fields: ['formatted_address', 'geometry', 'name'],
        strictBounds: false,
        types: ['(cities)'],
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          window.alert('No details available for input: \'' + place.name + '\'');
          return;
        }
        props.onChangeAutoCompleteCity(place.geometry.location);
      });
    }
  }, [gmapInitialised]);

  useEffect(() => {
    setShowMap(fullVisuel);
  }, [fullVisuel]);

  const onPageScroll = () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll > 20) {
      setFullVisuel(false);
    }
  };

  const onClickVIsuelOverlay = (e) => {
    e.preventDefault();
    setFullVisuel(true);
    window.scrollTo(0, 0);
  };

  return (
    <div className="page_banner_map">
      <div className="visuel_container">
        <div className="visuel">
          {
            iframeURL && (
              <iframe id="mapiframe" src={iframeURL} width="100%" height="100%" />
            )
          }

        </div>
        <div className={classNames('visuel_overlay', { 'hide': showMap })} onClick={onClickVIsuelOverlay}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={breadcrumbData}
            />
          </div>
        </div>
        {
          gmapInitialised && (
            <div className="map_options">
              <div className="custom_search">
                <input id="pac-input" type="text" placeholder={intl.formatMessage({ id: 'location.search.label' })} />
                <a className="icon">
                  OK
                </a>
              </div>
            </div>
          )
        }
      </div>
      <div className={classNames('intro_design', { 'fullVisuel': showMap })}>
        <div className={classNames('shaped_background_banner')}>
          <div className={classNames('bg_arrow_pointer_top')}>
            <BGArrowPointerTopMobile className="only_mobile_tablet" />
            <BGArrowPointerTopLeft className="only_desktop" />
          </div>
          <div className="scroll_icon_container">
            <ScrollIcon />
          </div>
          <div className="center_content">
            {children}
          </div>
          <div className={classNames('bg_arrow_pointer_bottom')}>
            <BGArrowPointerBottomMobile className="only_mobile_tablet" />
            <BGArrowPointerBottomLeft className="only_desktop" />
          </div>
        </div>
        <ShapeYellowArrowBanner className="shape_yellow_banner_bottom" />
      </div>
    </div>
  );
};

export default PageBannerMap;
